
.section.section--studies {
  @import "../../content_styles.scss";
  .content {
    @extend .flex;
    padding-top: 5%;
    @include mobile {
      display: block;
    }
  }
  .block {
    &--image {
      position: relative;
      width: 43.59%;
      margin-left: 5%;
      @include mobile {
        margin: auto;
      }
    }
    &--text {
      @extend .v-flex;
      //align-items: center;
      flex: 1;
      // padding-top: 14%;
      justify-content: center;
      padding-right: 5%;
      @include mobile {
        padding-left: 5%;
      }
      .logo-text {
        width: 70%;
      }
      .motto {
        font-size: 32px;
        font-size: 2.5rem;
        font-family: $acaslon-font;
        font-style: italic;
        color: $primary;
        margin: 30px 0 20px 0;
        margin: 3rem 0 2rem 0;
        @include mobile {
          color: $dark;
        }
      }
      .text-content {
        padding-right: unset;
        max-width: 65%;
        @include mobile {
          padding-left: 12%;
          padding-right: 12%;
        }
      }
    }
  }
}
