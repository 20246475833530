
path[name="Total Longevity Percentage"].recharts-radial-bar-background-sector {
  fill:#fff !important;
  border:none !important;
}

button {
  border: 1px solid black;
  padding: 1rem;
  margin: 1rem;
}


.section.section--longevity {
  .content-wrapper {
    flex-flow:column;
  }
  .content {
    @extend .flex;
    padding-top: 7%;
    @include less-than-tablet {
      display: block;
    }
  }

  .block {
    &--title {
      margin: 2% auto -4% auto;
      opacity: 0;
      @include less-than-tablet {
        display: none;
      }
    }
    &--image {
      position: relative;
      width: 43.59%;
      // margin-left: 5%;
      align-items: flex-start;
      justify-content: flex-start;
      position: fixed;
      left: 50vw;
      @include mobile {
        // position: relative;
        left: -15%;
        top: 0.6%;
        transform: scale(0.8);
        padding: 5% 20%;
        z-index: 5;
        background-color: white;
        border-bottom: 5px solid black;
        overflow: hidden;
      }
      @include tablet {
        left: 2.5%;
        top: 0.6%;
        padding: 3% 30%;
        z-index: 5;
        background-color: white;
        border-bottom: 5px solid black;
        overflow: hidden;
      }
      @include less-than-tablet {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: unset;
        width: unset;
        // position: fixed;
      }
      svg {
        width: 90%;
        // margin-left: -19%;
        margin-top: 80px;
      }
    }
    &--text {
      @extend .v-flex;
      flex: 1;
      // padding-right: 5%;
      color: $dark;
      max-width: 37%;
      padding-bottom: 100px;
      @include mobile {
        // padding-left: 16%;
        // padding-right: 16%;
        max-width: unset;
        // width: 50%;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        top: 350px;
        position: relative;        
      }
      @include tablet {
        max-width: unset;
        // width: 50%;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        top: 500px;
        position: relative;        

      }
      div.item {
        margin-top: 2rem;
        margin-left: -1rem;
        border: 1px dashed grey;
        padding: 10px;
        display: flex;
        flex-flow: column nowrap;
        &:first-of-type {
          margin-top:unset;
          @include less-than-tablet {
            margin-top: 2rem;
          }
        }
        @include less-than-tablet {
          width: 80%;
        }
        &.bp {
          label{
            &:nth-of-type(2) {
              margin-left: 133px;
            }
          }
          span.bp-slash {
            margin-left: 149px;
            font-size: 1.6rem;
          }
        }
        &.social {
          p {
            margin-bottom: 10px;
          }
          span.interaction-span {
            font-weight: 800;
            font-family: serif;
            font-size: 1.1rem;
          }
          label{
            &:first-of-type {
              margin-bottom: 10px;
            }
          }
        }
        div.propsNameGraph {
          width: 32rem;
          height: 16rem;
          margin-left: -20px;
          & span.recharts-legend-item-text {
            text-transform: capitalize;
          }
          @include less-than-tablet {
            margin-left: -49px;
          }
        }
        
      }
      fieldset {
        padding: .7em;
        // display: flex;
        // flex-flow: row nowrap;
        // justify-content: space-around;
      }
      label {
        display: inline-block;
        vertical-align: text-top;
        margin-bottom: 3px;
        margin-left: 15px;
        font-size: 1.3rem;
        &.note {
          margin-top: 15px;
        }
      }
      input {
        border: 1px solid black;
        width: 2em;
        font-size: 1.6rem;
        padding: 4px;
        margin: 2px;
        &:disabled {
          cursor: not-allowed;
          background-color: lightgray;
          opacity: 0.3;
        }
        &.leg.extension.number.input {
          width: 2.5em;
        }
        &.sbp.number.input {
          width: 1.7em;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
          -webkit-appearance: none;
          margin: 0;
        }
        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }        
        &[type="radio"] {
          vertical-align: middle;
          margin: 0;
          width: 2rem;
          height: 1.5rem;
        }
        &[type="checkbox"] {
          vertical-align: middle;
          margin: 0;
          width: 2rem;
          height: 2rem;
          margin: 0 5px 0 -22px;
        }
        &.range-slider-input {
          $vo2Color: #A7F2CF;
          $strengthColor: #479393;
          $smokingColor: #ECA282;
          $socialColor: #5465FF;
          $bpColor: #990D35;

          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 80%;
          height: 20px;
          border-radius: 50em;
          background: #2d2d2d;
          outline: none;
          padding: 5px;
          margin: 5px;
          cursor: default;
          &.vo2 {
            &::-webkit-slider-thumb {
              background: $vo2Color;
            }
          }
          &.strength {
            &::-webkit-slider-thumb {
              background: $strengthColor;
            }
          }
          &.smoking {
            &::-webkit-slider-thumb {
              background: $smokingColor;
            }
          }
          &.social {
            &::-webkit-slider-thumb {
              background: $socialColor;
            }
          }
          &.bp {
            &::-webkit-slider-thumb {
              background: $bpColor;
            }
            &.sbp {
              width: 50%;
            }
          }
        
          // Range Handle
          &::-webkit-slider-thumb {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            // background: var( - white);
            cursor: pointer;
            transition: background .15s ease-in-out;
            &:hover{
            transform: scale(0.97);
            }
            &:active {
            transform: scale(0.92);
            }
          }
        }
      }
      
    }
  }

  .citation.para {
    margin-bottom: 0.6rem;
    line-height: 1;

  }
  div.item.citations {
    h3 {
      margin-top: 0.8rem;
    }
  }
}
