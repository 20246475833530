html {
  font-size: 80%;
  @include mobile {
    // font-size: 37.5%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: $primary-font;
  line-height: 1.2;
}

html, body, #root {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

button, input {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: $primary-font;
}

button {
  cursor: pointer;
}

p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul, ol {
  li {
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0;

  }
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.v-flex {
  display: flex;
  flex-direction: column;
}

.h-center {
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.a-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.t-center {
  text-align: center;
}

.spacer {
  flex: 1;
}

.full-flex {
  flex: 1;
}

.nodisplay {
  display: none;
}

.invisible {
  visibility: hidden;
}


a {
  color: $primary;
  transition: all 0.3s ease-out;
  cursor: pointer;
  &:hover {
    color: $secondary;
    & img {
      transition: all 0.5s ease-out;
      transform: scale(1.08);
    }
  }
}
