.section.section--contact {
  .content-wrapper {
    justify-content: center;
  }
  .content {
    width: 100%;
    @extend .flex;
    padding-top: 7%;
    @include mobile {
      display: block;
    }
  }
  .block {
    &--image {
      position: relative;
      width: 43.59%;
      margin-left: 5%;
      margin-right: 5%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      & iframe {
        height: 33rem;
        @include mobile {
          height: 20rem;
        }
      }
      @include mobile {
        margin: auto;
        width: 80%;
      }
      .purpose-cover {
        margin-left: 20%;
        width: 80%;
      }
      h6 {
        font-size: 1.5rem;
        margin: -0.6rem 0 -0.6rem 0.6rem;

        &:before {
          content: '';
          width: 7.894736px;
          height: 10px;
          background-image: url('../../../../public/images/tri-right.svg');
          display: inline-block;
          position: relative;
          bottom: 1px;
          left: -2px;
          transform: scaleX(1.5);
        }
      }
      h5 + h6 {
        margin: 0 0 -0.6rem 0.6rem;
      }      
    }
    &--text {
      @extend .v-flex;
      flex: 1;
      padding-right: 5%;
      color: $dark;
      z-index: 1;
      position: relative;
      @include mobile {
        padding-top: 5%;
        padding-left: 10%;
        padding-right: 10%;
      }
      a.booking {
        font-weight: bold;
        text-decoration: none;
        font-size: 2.1rem;
        padding: 1.6rem;
        background-color: #333;
        margin-right: 28%;
        margin-top: 3%;
        text-align: center;
        color: white;
        word-wrap: break-word;
        &:hover {
          color: $primary;
        }
        @include mobile {
          margin-right: unset;
          display: inline-block;
        }
      }
      a.booking + h5 {
        margin-top: 4.8rem;
        // margin-bottom: 1.8rem;
      }
      h2 {
        margin-top: 2.1rem;
      }
      h5 {
        font-family: $primary-font;
        font-weight: bold;
        font-size: 21px;
        font-size: 2.1rem;
      }
      h6 {
        font-size: 1.5rem;
        margin: 1.6rem 0 -0.6rem 0.6rem;

        &:before {
          content: '';
          width: 7.894736px;
          height: 10px;
          background-image: url('../../../../public/images/tri-right.svg');
          display: inline-block;
          position: relative;
          bottom: 1px;
          left: -2px;
          transform: scaleX(1.5);
        }
      }
      h5 + h6 {
        margin: 0 0 -0.6rem 0.6rem;
      }
      .text-content {
        margin: 6px 0 10px 0;
        margin: 0.6rem 0 1rem 0;
        @include mobile {
          padding-right: 0;
        }
      }
    }
  }
  .section__quote {
    @include mobile {
      padding-left: 10%;
      padding-right: 10%;
    }
  }
}
