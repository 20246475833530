.section.section--patients {
  .container {
    position: relative;
    height: unset;
    div.click-here {
      position: absolute;
      bottom: 91px;
      right: 2%;
      width: 300px;
      height: 100px;
      display: flex;
      flex-flow: row nowrap;
      width: 46%;
      justify-content: flex-end;
      img {
        width: 90%;
        height: 100%;
        transform: rotateZ(-20deg);
        // padding-right: 19px;
      }
      p.click-here {
        font-size: 0.8rem;
        width: 100px;
        // margin-right: 40px;
      }
    }
  }
  .section__tip {
    top: 15%;
  }
  .content {
    @extend .flex;
    padding-top: 5%;
    @include mobile {
      display: block;
    }
  }
  h1 {
    @extend h5;
  }
  .block {
    &--image {
      position: relative;
      width: 43.59%;
      margin-left: 5%;
      @include mobile {
        margin: auto;
        width: 80%;
      }
      .man-transparent {
        width: 100%;
        transform: scale(3);
        opacity: 0;
        transition: transform var(--transition-speed) ease-in, opacity 1.2s ease-out 0.4s;
        &.loaded {
          transition-delay: calc(var(--transition-speed) - 0.4s);
          transform: scale(1);
          opacity: 1;
        }
      }
      .longevity {
        width: 100%;
      }
      .meaningspan {
        width: 100%;
      }
      .tri-down-outline {
        position: absolute;
        width: 108%;
        left: -10%;
        transform: translateY(5%);
      }
      &.longevity {
        @include less-than-tablet {
          margin: 0 0 20px 5%;
        }
      }
    }
    &--text {
      @extend .v-flex;
      align-items: center;
      flex: 1;
      // padding-top: 14%;
      justify-content: center;
      padding-right: 5%;
      @include mobile {
        padding-left: 5%;
      }
      .logo-text {
        width: 70%;
      }
      .motto {
        font-size: 32px;
        font-size: 2.5rem;
        font-family: $acaslon-font;
        font-style: italic;
        color: $primary;
        margin: 30px auto 20px auto;
        margin: 3rem auto 2rem auto;
        @include mobile {
          color: $dark;
        }
      }
      .text-content {
        padding-right: unset;
        max-width: 75%;
        @include mobile {
          padding-left: 12%;
          padding-right: 12%;
        }
      }
    }
  }
}
.section__quote:last-of-type {
  padding-bottom: 19%;
}