:root {
  --menu-scale: 1;
}
.section.section--menu {
  $menu-item-spacing: 70px;
  $menu-item-mobile-spacing: 100px;
  $menu-items-start: 100px;
  $menu-item-mobile-start: 150px;
  background-color: $dark;
  .section__content {
    @include mobile {
      padding-top: 9%;
      // transform:scale(1.8);
    }
    @include tablet {
      padding-top: 5%;
    }
  }
  a {
    text-decoration: none;
  }
  .nav {
    @extend .v-center;
    z-index: 1;
    justify-content: space-between;
    position: relative;
    margin: 2% 4% 0;
    @include mobile {
      flex-direction: column;
      margin: 0;
      height: 1vh;
    }

    &__logo {
      // width: 440px;
      display: none;
      width: 20%;
      @include mobile {
        width: 35%;
        margin: auto;
      }
    }
    .btn.btn--text.btn--close {
      position: absolute;
      top: -32%;
      right: 0%;
      // font-size: 4rem;
      text-align: center;
      display: flex;
      flex-flow: row nowrap;
      flex: 1 1 auto;
      justify-content: center;
      max-width: 45px;
      // min-width: 100px;
      // min-height: 100px;
      z-index: 9999;
      img {
        border: 1px solid $primary;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
      @include tablet {
        right: 5%;
        top: 120%;
        transform: translate(-50%, -50%);
        width: 6vw;
        // height: 6vw;
      }
      @include mobile {
        top: -7.6vw;
        left: 5vw;
        right: unset;
        width: 20vw;
      }
    }
    .motto {
      display: none;
      @extend .v-center;
      font-size: 32px;
      font-size: 2.5rem;
      font-family: $acaslon-font;
      font-style: italic;
      color: $primary;
      opacity: 0.5;
      @include mobile {
        display: none;
        font-size: 2.8rem;
        margin-top: 6px;
      }
    }
    span.menu.menu--label {
      @extend .a-center;
      top: 63%;
      font-family: $acaslon-font;
      font-style: italic;
      font-weight: 100;
      font-size: 15px;
      font-size: 2rem;
      color: white;
      opacity: 0.8;
      width: 100%;
      text-align: center;
      letter-spacing: 2px;
      // transform: scale(var(--menu-scale));
      img {
        width: 16px;
      }
      @include tablet {
        top: 255%;
      }
      @include mobile {
        // display: none;
        top: 130%;
        border-bottom: 4px solid $primary;      
      }
    }
  }
  .content {
    flex: 1;
    position: relative;
    overflow: hidden;
    @include tablet {
      overflow-y: scroll;
    }
    @include mobile {
      // margin-top: 10%;
    }
  }
  .menu-box-container.a-center{
    top: 50%;
    @include tablet {
      top:56vw;
    }
    @include mobile {
      top: 70vw;
    }
  }
  .box {
    width: 820px;
    height: 820px;
    transform: scale(var(--menu-scale));
    @include mobile {
      transform: scale(calc(var(--menu-scale) + 0.12));
      font-size: 1.8rem;
    }
    & > * {
      position: absolute;
    }
    .motto-mobile {
      left: 50%;
      top: -10%;
      transform: translateX(-50%);
      display: none;
      font-size: 32px;
      font-size: 3.2rem;
      font-family: $acaslon-font;
      font-style: italic;
      color: $primary;
      @include mobile {
        display: none;
      }
    }
    div.bg-logo {
      position: absolute;
      .double-tri {
        width: 782px;
        left: 18px;
        top: 105px;
        position: relative;
        @include mobile {
          top: 142px;
        }
      }
      .slash {
        position: relative;
        height: 778px;
        right: -332px;
        top: -493px;
      }
    }
    .btn--image {
      left: 50%;
      transform: translateX(-50%);
      width: 540px;
      span {
        @extend .a-center;
        font-family: $lato-font;
        font-weight: normal;
        font-size: 27px;
        font-size: 3rem;
        color: $white;
        letter-spacing: 7px;
        letter-spacing: 0.7rem;
        text-align: left;
        white-space: nowrap;
        @include less-than-tablet {
          font-size: 6.1rem;
        }
      }
      &.highlight{
        opacity: 0.4;
        transform: scale(.85) translateX(-60%);
        span {
        }
      }
      @include less-than-tablet {
        width: 90%;
        font-size: 5.4rem;
      }
      // top: calc();
    }
    .btn--text {
      font-family: $primary-font;
      font-weight: bold;
      font-size: 21px;
      font-size: 4.1rem;
      color: white;
      letter-spacing: 4.5px;
      letter-spacing: 0.45rem;
      text-align: center;
      white-space: nowrap;
      border-bottom: 1px solid $primary;
      border-bottom: 1px solid #cba47d;
      border: 1px solid #cba47d;
      padding-left: 4.5px;
      padding-left: 0.45rem;
      padding: 1rem 2.5rem;
      @include tablet {

      }
      @include mobile {
        font-size: 8.1rem;
      }
    }
    div.big-buttons {
      flex-flow: row nowrap;
      justify-content: space-around;
      width: 100%;
      @include tablet {
        right: unset;
        flex-flow: row nowrap;
        justify-content: space-around;
        width: 100%;
      }
      @include mobile {
        flex-flow: row nowrap;
        top: -10vw;
        width: 100%;
        right: unset;
      }
      a {
        background-color: $primary;
        &:hover {
          background-color: $secondary;
        }
      }
      .btn--book {
        
      }
      .btn--call {
  
      }

    }
    .btn--home {
      // width: 314px;
      // left: 312px;
      top: $menu-items-start;
      @include less-than-tablet {
        top: $menu-item-mobile-start;
      }
      span {
        margin-top: -2px;
        margin-left: 3px;
        @include less-than-tablet {
          margin-top: -10px;
        }        
      }
    }
    .btn--purpose {
      // width: 314px;
      // left: 315px;
      // top: 170px;
      top: $menu-items-start + $menu-item-spacing * 1;
      @include less-than-tablet {
        top: $menu-item-mobile-start + $menu-item-mobile-spacing * 1;
      }
      span {
        margin-top: -2px;
        margin-left: 3px;
        @include less-than-tablet {
          margin-top: -10px;
        }        
      }
    }
    .btn--data {
      // width: 314px;
      // left: 283px;
      // top: 226px;
      top: $menu-items-start + $menu-item-spacing *2;
      @include less-than-tablet {
        top: $menu-item-mobile-start + $menu-item-mobile-spacing * 2;
      }

      span {
        margin-top: -2px;
        margin-left: -1px;
        @include less-than-tablet {
          margin-top: -10px;
        }        
      }
    }
    .btn--services {
      // width: 314px;
      // left: 250px;
      // top: 282px;
      top: $menu-items-start + $menu-item-spacing *3;
      @include less-than-tablet {
        top: $menu-item-mobile-start + $menu-item-mobile-spacing * 3;
      }
      span {
        margin-top: -2px;
        margin-left: 3px;
        @include less-than-tablet {
          margin-top: -10px;
        }        
      }
    }
    .btn--cases {
      // width: 314px;
      // left: 184px;
      // top: 394px;
      top: $menu-items-start + $menu-item-spacing *4;
      @include less-than-tablet {
        top: $menu-item-mobile-start + $menu-item-mobile-spacing * 4;
      }
      span {
        margin-top: -2px;
        margin-left: -1px;
        @include less-than-tablet {
          margin-top: -10px;
        }        
      }
    }
    // .btn--values {
    //   // width: 314px;
    //   // left: 217px;
    //   top: $menu-items-start + $menu-item-spacing *5;
    //   @include less-than-tablet {
    //     top: $menu-item-mobile-start + $menu-item-mobile-spacing * 5;
    //   }
    //   span {
    //     margin-top: -2px;
    //     margin-left: 2px;
    //     @include less-than-tablet {
    //       margin-top: -10px;
    //     }        
    //   }
    // }
    .btn--about {
      // width: 314px;
      // left: 147px;
      top: $menu-items-start + $menu-item-spacing *4;
      @include less-than-tablet {
        top: $menu-item-mobile-start + $menu-item-mobile-spacing * 4;
      }
      span {
        margin-top: -2px;
        margin-left: 2px;
        @include less-than-tablet {
          margin-top: -10px;
        }
      }
    }
    // .btn--values {
    //   width: 216px;
    //   left: 3px;
    //   top: 359px;
    // }
    // .btn--strengths {
    //   // width: 198px;
    //   // left: 291px;
    //   top: $menu-items-start + $menu-item-spacing *7;
    //   span {
    //     letter-spacing: 4px;
    //     margin-left: 2px;
    //     margin-top: -3px;
    //   }
    // }
    .btn--pricing {
      // width: 218px;
      // & img {
      //   height: 285px;
      // }
      // left: 510px;
      // top: 415px;
      // width: 750px;
      top: $menu-items-start + $menu-item-spacing *5;
      
      @include less-than-tablet {
        top: $menu-item-mobile-start + $menu-item-mobile-spacing * 5;
      }
      span {
        letter-spacing: 3px;
        font-size: 3rem;
        // margin-left: 5px;
        text-align: center;
        // font-weight: bold;
        @include less-than-tablet {
          margin-top: -10px;
          font-size: 6.1rem;
        }        
      }
    }
    .btn--contact {
      // left: 350px;
      // top: 516px;
      top: $menu-items-start*1.2 + $menu-item-spacing*7;
      left: 80px;
      font-size: 3rem;
      border: unset;
      border-bottom: 1px solid $primary;
      @include less-than-tablet {
        top: $menu-item-mobile-start*1.5 + $menu-item-mobile-spacing * 7;
        right: unset;
        left: 9vw;
      }
    }
    .btn--faq {
      // left: 350px;
      // top: 516px;
      top: $menu-items-start*1.2 + $menu-item-spacing*7;
      right: 134px;
      font-size: 3rem;
      border: unset;
      border-bottom: 1px solid $primary;
      @include less-than-tablet {
        top: $menu-item-mobile-start*1.5 + $menu-item-mobile-spacing * 7;
        left: unset;
        right: 15vw;
      }
    }
    // .btn--contact {
    //   left: 191px;
    //   top: 656px;
    //   @include mobile {
    //     // display: none;
    //     top: 682px;
    //   }
    // }
    // .btn--pricing {
    //   left: 474px;
    //   top: 661px;
    //   @include mobile {
    //     top: 682px;
    //   }
    // }
  }
  .nav-mobile {
    display: none;
    z-index: 1;
    @include mobile {
      display: flex;
      position: fixed;
      bottom: 0;
      width: 100vw;
    }
    & > * {
      @extend .center;
      flex: 1;
      height: 64px;
      font-size: 15px;
      font-size: 3rem;
      color: $primary;
      letter-spacing: 2px;
    }
    .btn.btn--login {
      // background-color: #F2F2F2;
      color: white;
      span {
        margin-right: 12px;
        margin-right: 1.2rem;
      }
      img {
        width: 12px;
      }
    }
  }
}
