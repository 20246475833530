.section.section--about {
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 19%;
    @include mobile {
      padding: 5% 0;
      flex: none;
      flex-direction: column-reverse;
      // display: block;
    }
    .box {
      width: 54%;
      margin-left: 10%;
      padding: 50px 60px;
      padding: 5rem 6rem;
      @include mobile {
        box-sizing: border-box;
        margin-top: 10px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 16px;
        order: 2;
      }
      &--origins {
        background-color: black;
      }
      &--profile {
        @include less-than-tablet {
          display: block;
        }
        @extend .flex;
        background-color: rgba(219, 212, 204, 0.28);
        margin-top: 24px;
        margin-top: 2.4rem;
        padding-top: 0;
      }
      &__title {
        @extend .v-center;
        @include mobile {
          display: block;
        }
        .origins {
          font-family: $primary-font;
          font-size: 44px;
          font-size: 4.4rem;
          color: $white;
          margin-right: 60px;
          margin-right: 6rem;
          font-weight: normal;
          @include mobile {
            margin-right: 16px;
          }
        }
        .philosophy {
          font-family: $primary-font;
          font-size: 14px;
          font-size: 1.4rem;
          font-weight: 300;
          color: $white;
          line-height: 1.4;
        }
      }
      &__content {
        @extend .t-center;
        font-size: 30px;
        font-size: 3rem;
        font-family: $acaslon-font;
        font-style: italic;
        color: $primary;
        margin-top: 50px;
        margin-top: 5rem;
        @include mobile {
          margin-top: 16px;
        }
      }
      &__info {
        margin-right: 60px;
        @include less-than-tablet {
          margin-right: 16px;
          margin-bottom: 16px;
          width: 50%;
          float: left;
        }
        .zh-photo {
          width: 250px;
          @include less-than-tablet {
            width: 100%;
          }
        }
        .zh-title {
          font-family: $primary-font;
          font-size: 16px;
          font-size: 1.6rem;
          color: $dark;
          margin-top: 60px;
          margin-top: 6rem;
          @include mobile {
            margin-top: 32px;
          }
        }
      }
      &__bio {
        font-family: $primary-font;
        font-size: 14px;
        font-size: 1.4rem;
        color: $dark;
        margin-top: 50px;
        margin-top: 5rem;
        span.listItem {
          font-size: 1rem;
          margin-left: 5%;
          margin-top: -3px;
        }
        @include mobile {
          margin-top: 16px;
        }
      }
    }
    .contact-info {
      position: absolute;
      right: 5%;
      top: 30%;
      padding: 10px;
      background-color: white;
      @include mobile {
        position: static;
        width: 90%;
        margin: 5% auto 0 5%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: flex-start;
      }
      .logo-circle {
        width: 84px;
        // @include mobile {
        //   width: 48px;
        // }
      }
      .address {
        font-family: $primary-font;
        font-size: 18px;
        font-size: 1rem;
        font-weight: bold;
        color: $grey;
        margin-top: 16px;
        @include mobile {
          // margin-top: 0;
          margin-left: 8px;
        }
      }
      .email {
        @extend .address;
        // word-break: break-word;
        max-width: 150px;
        @include mobile {
          max-width: unset;
        }
      }
      .phone {
        margin-top: 16px;
        &::before {
          content: "\260e";
          margin-right: 0.5em;
        }
      }
    }
  }
}
