.section.section--pricing {
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-left: 33%;
    padding-top: 5%;
    @include mobile {
      flex: none;
      justify-content: flex-start;
      padding: 5% 0;
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }

    h5 {
      line-height: 1.5;
      span {
        font-weight: normal;
        color: $primary;        
        span.perYear {
          font-size: xx-small;
          vertical-align: super;
          margin-left: -5px;
          text-shadow: none;
        }
        &.price {
          font-size: xx-large;
          text-shadow: 1px 1px 0px #2d2d2d, lightgrey 2px 2px 2px;
        }
      }
    }

    a.booking {
      font-weight: bold;
      text-decoration: none;
      font-size: 2.1rem;
      padding: 1.6rem;
      background-color: #333;
      margin-right: 28%;
      margin-top: 3%;
      text-align: center;
      color: white;
      word-wrap: break-word;
      &:hover {
        color: $primary;
      }
      @include mobile {
        margin-right: unset;
        display: inline-block;
      }
    }
    a.booking + h5 {
      margin-top: 4.8rem;
      margin-bottom: 1.8rem;
    }
    .text-content {
      margin-bottom: 1.6rem;
      @include mobile {
        padding-right: unset;
      }
    }
    .membership {
      @extend .v-center;
      margin: 12px 0 0px -100px;
      @include mobile {
        margin-left: -40px;
      }
      .logo-circle {
        width: 84px;
        margin-right: 16px;
        @include mobile {
          width: 32px;
          margin-right: 8px;
        }
      }
    }
    .description {
      @extend .v-center;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 3.2rem;
      @include mobile {
        margin-top: unset;
      }
      & div {
        // margin: 1.6rem 0 1rem 0;
      }
      & h4 {
        font-size: 1.7rem;
        margin: 0.6rem 0 0rem 0;
        color: $primary;
      }
      & p {
        // max-width: 150px;
        font-size: 1.5rem;
        margin: 0.6rem 0 1.6rem 0;
        // padding-right: 28%;
        @include mobile {
          padding-right: unset;
        }
      }
    }
    .credits {
      margin-left: 16%;
      @include mobile {
        margin-left: 8%;
      }
      .triangles {
        width: 64px;
        margin-right: 16px;
        @include mobile {
          width: 40px;
          margin-right: 12px;
        }
      }
      .credits-content {
        border-left: 2px solid #333333;
        margin-left: 50px;
        margin-top: -12px;
        @include mobile {
          margin-left: 32px;
        }
        .credit {
          @extend .v-center;
          font-family: $primary-font;
          font-weight: 300;
          font-size: 17px;
          font-size: 1.7rem;
          color: $primary;
          padding: 8px 0;
          margin-left: -1px;
          @include mobile {
            padding: 6px 0;
          }
          .triangle {
            width: 16px;
            margin-right: 12px;
            @include mobile {
              width: 12px;
              margin-right: 8px;
            }
          }
          &:first-child {
            padding-top: 24px;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
    .initialVisit {
      .text-content {
        margin-bottom: 6rem;
      }
    }
  }
}
