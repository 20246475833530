$vo2Color: #A7F2CF;
$strengthColor: #479393;
$smokingColor: #ECA282;
$socialColor: #5465FF;
$bpColor: #990D35;


.basic.longevity.mini-app {
  width: 30vw;
  height: 30vw;
  padding: 1.5%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  @include mobile {
    width: 98vw;
    height: 98vw;
    padding: 0;
  }  
}

.LongevityBasicComponent {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  h1 {
    font-size: 1.6rem;
  }
  h2 {
    font-size: 1.3rem;
  }
  img.overlay {
    display: none;
    &.show {
      display: flex;
      width: 82%;
      padding-right: 40px;
      background-color: white;
      z-index: 9999;
      position: absolute;
      top: -9px;
      left: -19px;
      @include less-than-tablet {
        width: 106vw;
        padding-top: 40px;
        top: -18px;
        left: -23px;
        display: none;
      }
    }
  }
  &#LongevityBasicComponent {
    .combo-chart-div {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      z-index: 10;
      position: relative;
      &> * {
        width: 100%;
        position: relative;
      }
      .info-dot {
        background-color: yellow;
        position: absolute;
        top: 111px;
        left: 84px;
        width: 10px;
        height: 10px;
        z-index: 9999;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        cursor: help;
        @include mobile {
          display: none;
        }
        &:hover {
          visibility: hidden;
        }
        p {
          font-size:7px;
          font-weight: bold;
          cursor: help;
        }
      }
    }
    
    .recharts-wrapper {
      display: flex;
      flex-flow: column-reverse nowrap;
      height: 400px !important;
      width: 200px !important;
      top: -50px;
      left: -19px;
      > svg {
        transform-origin: right;
        transform: rotateZ(90deg);
        margin-top: -75px;
        .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick {
          &:first-of-type {
            .recharts-cartesian-axis-tick-value{
              transform-origin: center;
              transform: rotateZ(-90deg) translate(-121px, -135px);
            }
          }
          &:nth-of-type(2) {
            .recharts-cartesian-axis-tick-value{
              transform-origin: center;
              transform: rotateZ(-90deg) translate(-53px, -72px);
            }
          }
          &:nth-of-type(3) {
            .recharts-cartesian-axis-tick-value{
              transform-origin: center;
              transform: rotateZ(-90deg) translate(12px, -8px);
            }
          }
          &:nth-of-type(4) {
            .recharts-cartesian-axis-tick-value{
              transform-origin: center;
              transform: rotateZ(-90deg) translate(77px, 57px);
            }
          }
          &:nth-of-type(5) {
            .recharts-cartesian-axis-tick-value{
              transform-origin: center;
              transform: rotateZ(-90deg) translate(143px, 121px);
            }
          }
        }
      }
      div.recharts-legend-wrapper {
        position: relative !important;
        width: 500px !important;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        ul {
          width: 90% !important;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
        }
        li.recharts-legend-item {
          margin-right: unset !important;
          &:last-of-type {
            opacity: 0;
            padding-right: 25px;
          }
        }
      }
      div.recharts-tooltip-wrapper {
        top: -50px !important;
        left: 320px !important;
        width: 150px;
      }
    }
    
    .input-ranges-div {
      z-index: 20;
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: flex-start;
      margin-top: -260px;
      .range.div {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px dashed blue;
        h2 {
          width: 110px;
        }
        input {
          width: 150px;
        }
        p {
          width: 70px;
        }
      }
    }
    
    .range-slider-input {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 80%;
      height: 20px;
      border-radius: 50em;
      background: #2d2d2d;
      outline: none;
      padding: 5px;
      margin: 5px;
      cursor: pointer;
      &.vo2 {
        &::-webkit-slider-thumb {
          background: $vo2Color;
        }
      }
      &.strength {
        &::-webkit-slider-thumb {
          background: $strengthColor;
        }
      }
      &.smoking {
        &::-webkit-slider-thumb {
          background: $smokingColor;
        }
      }
      &.social {
        &::-webkit-slider-thumb {
          background: $socialColor;
        }
      }
      &.bp {
        &::-webkit-slider-thumb {
          background: $bpColor;
        }
      }
    
      // Range Handle
      &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        // background: var( - white);
        cursor: pointer;
        transition: background .15s ease-in-out;
        &:hover{
        transform: scale(0.97);
        }
        &:active {
        transform: scale(0.92);
        }
      }
    }
    
  }
}
