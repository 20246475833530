
.section.section--card {
  //@import "../../content_styles.scss";
  .content {
    @extend .flex;
    padding-top: 5%;
    @include mobile {
      display: block;
    }
  }
  .block {
    &--image {
      position: relative;
      width: 43.59%;
      margin-left: 5%;
      @include mobile {
        margin: auto;
      }
    }
    &--text {
      @extend .v-flex;
      align-items: flex-start;
      flex: 1;
      // padding-top: 14%;
      justify-content: center;
      padding-right: 5%;
      @include mobile {
        padding-left: 5%;
      }
      .logo-text {
        width: 70%;
      }
      .motto {
        font-size: 35px;
        font-size: 2.5rem;
        font-family: $acaslon-font;
        font-style: italic;
        color: $primary;
        margin: 30px 0 20px 0;
        margin: 3rem 0 2rem 0;
        @include mobile {
          color: $dark;
        }
      }
      .text-content {
        padding-right: unset;
        max-width: 65%;
        @include mobile {
          padding-left: 12%;
          padding-right: 12%;
        }
      }
      & .motto {
        margin-bottom: .7rem;
        text-decoration: underline;
      }      
      div.contact-details, div.activities {
        display: flex;
        flex-direction: column;
        margin-left: 1.6rem;
        & a.element {
          font-size: 1rem;
          padding: 1rem;
          text-align: left;
          &.map {
            width: fit-content;
          }
        }
        &>p {
          margin-top: 10%;
        }
        & p.tip {
          font-size: 0.5rem;
        }
        & iframe {
          width: 385px;
          height: 220px;
        }
        @include mobile {
          margin-left: unset;
          max-width: 100vw;
          & iframe {
            width: 90vw;
            height: 50vw;
          }
        }
      }
      img.zh-photo {
        width: 100%;
      }
      img.logo-circle {
        width: 20%;
        margin: 1% auto;
      }
      a.contact, a.element {
        font-weight: bold;
        text-decoration: none;
        font-size: 3.5rem;
        padding: 1.6rem;
        background-color: #333;
        //margin-right: 28%;
        margin-top: 3%;
        text-align: center;
        color: white;
        word-wrap: break-word;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & img {
          max-height: 20px;
          margin-right: 3%;
        }
        &:hover {
          color: $primary;
        }
        @include mobile {
          margin-right: unset;
          // display: inline-block;
        }
      }
    }
  }
}
