@import "./menu/index.scss";
@import "./patients/index.scss";
@import "./purpose/index.scss";
@import "./data-assisted/index.scss";
@import "./cases/index.scss";
@import "./contact/index.scss";
@import "./about/index.scss";
@import "./strengths/index.scss";
@import "./benefits/index.scss";
@import "./values/index.scss";
@import "./services/index.scss";
@import "./pricing/index.scss";
@import "./faq/index.scss";
@import "./what-is-health/index.scss";
@import "./studies/index.scss";
@import "./card/index.scss";
@import "./longevity/index.scss";

.switch-wrapper {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.section {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $white;
  transition: all var(--transition-speed);

  .content-wrapper {
    display: flex;
    justify-content: center;
    flex: 1;

    @include mobile {
      justify-content: flex-start;
      flex: none;
      padding-bottom: 10%;
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: auto;
    box-sizing: border-box;

    &.container {
      max-width: 1170px;
    }

    @include mobile {
      padding-top: $header-height;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  &__tip {
    font-family: $primary-font;
    font-size: 1.3rem;
    font-weight: 300;
    position: absolute;
    top: 37%;
    right: 5.7rem;
    color: $grey;
    letter-spacing: 0.9rem;
    transform-origin: 100% 50%;
    transform: rotate(-90deg) translateX(50%);

    @include mobile {
      display: none;
    }
  }

  &__quote {
    text-align: center;
    font-size: 2.7rem;
    font-family: $acaslon-font;
    font-style: italic;
    color: $primary;
    padding-bottom: 10%;
    margin-top: 3%;
    text-shadow: 1.3px 1.5px 1.7px gray;
    font-weight: 600;
    @include mobile {
      padding-left: 16%;
      padding-right: 16%;
    }
  }

  h5 {
    font-family: $primary-font;
    font-weight: bold;
    font-size: 2.1rem;
  }

  .option {
    text-align: center;
    font-size: 2.7rem;
    font-family: $acaslon-font;
    font-style: italic;
    color: $secondary;
    background-color: rgba(219, 212, 204, 0.28);
    padding: 0.6rem 0;
    margin-top: 2rem;
    cursor: pointer;

    &--selected,
    &--hovered {
      background-color: black;
      color: $lightgrey;
    }
  }

  .goto-prev,
  .goto-next {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    @include less-than-tablet {
      z-index: 9999;
    }

    img {
      width: 40px;
      transform-origin: center;
      transform: rotateZ(90deg);
      // background-color: $primary;
      border-radius: 50%;
      @include mobile {
        width: 30px;
      }
    }
  }

  .goto-prev {
    top: 10px;
    @include less-than-tablet {
      top: 7px;
    }
  }

  .goto-next {
    bottom: 10px;
    &[href="/purpose"] {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(0.5) translateX(-100%);
      animation: pulse 2s infinite;
      animation-delay: 5s;
      border-radius: 50%;
      >img {
        width: 80px;
      }
      @include mobile {
        
      }
    }
  }
}
@keyframes pulse {
	0% {
		transform: scale(0.95) translateX(-50%);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1) translateX(-50%);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95) translateX(-50%);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.slide-left {
  .page {
    &-enter {
      // left: 100%;
      left: 0;
      opacity: 1;
    }
    &-exit,
    &-enter-active {
      left: 0;
      opacity: 1;
    }
    &-exit-active {
      left: -100%;
      // left: 0;
      opacity: 1;
    }
  }
}

.slide-up {
  .page {
    &-enter {
      top: 100%;
      opacity: 0;
    }
    &-exit,
    &-enter-active {
      top: 0;
      opacity: 1;
    }
    &-exit-active {
      top: -100%;
      opacity: 0;
    }
  }
}

.slide-right {
  .page {
    &-enter {
      left: -100%;
      opacity: 0;
    }
    &-exit,
    &-enter-active {
      left: 0;
      opacity: 1;
    }
    &-exit-active {
      // display:none;
      // left: 100%;
      left: 0;
      opacity: 1;
    }
  }
}
.slide-down {
  .page {
    &-enter {
      top: -100%;
      opacity: 0;
    }
    &-exit,
    &-enter-active {
      top: 0;
      opacity: 1;
    }
    &-exit-active {
      top: 100%;
      opacity: 0;
    }
  }
}

.zoom-in {
  .page {
    &-enter {
      transform: scale(0);
      opacity: 0;
    }
    &-exit,
    &-enter-active {
      transform: scale(1);
      left: 0;
      opacity: 1;
    }
    &-enter-done {
      left: 0;
    }
    &-exit-active {
      transform: scale(3)
        translate(var(--zoom-in-x-adjust), var(--zoom-in-y-adjust));
      opacity: 0;
    }
  }
}

.zoom-out {
  .page {
    &-enter {
      transform: scale(5);
      opacity: 0;
    }
    &-exit,
    &-enter-active {
      transform: scale(1);
      opacity: 1;
    }
    &-enter-done {
      left: 0%;
    }
    &-exit-active {
      transform: scale(0);
      opacity: 0;
    }
  }
}

.text-content {
  color: $dark;
  font-family: $primary-font;
  font-weight: 300;
  font-size: 1.5rem;
  padding-right: 28%;
  margin: 1.6rem 0 1rem 0;

  @include mobile {
    // font-size: 2rem;
  }
}

.block--image {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
