@import url('https://fonts.googleapis.com/css?family=Lato&text=YOUPRITYFSERTCALGMVLNHB&display=swap');

@font-face {
  font-family: 'ACaslonPro';
  font-display: swap;
  src:  local('ACaslonPro'),
        local('Adobe Caslon Pro'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Regular-subset.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Regular.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ACaslonPro';
  font-display: swap;
  src:  local('ACaslonPro-Italic'),
        local('Adobe Caslon Pro Italic'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Italic-subset.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Italic.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Italic.otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'ACaslonPro';
  font-display: swap;
  src:  local('ACaslonPro-Semibold'),
        local('Adobe Caslon Pro Semibold'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Semibold-subset.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Semibold.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Semibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ACaslonPro';
  font-display: swap;
  src:  local('ACaslonPro-SemiboldItalic'),
        local('Adobe Caslon Pro Semibold Italic'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-SemiboldItalic-subset.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-SemiboldItalic.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-SemiboldItalic.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'ACaslonPro';
  font-display: swap;
  src:  local('ACaslonPro-Bold'),
        local('Adobe Caslon Pro Bold'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Bold-subset.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Bold.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ACaslonPro';
  font-display: swap;
  src:  local('ACaslonPro'),
        local('Adobe Caslon Pro'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-BoldItalic-subset.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-BoldItalic.woff2') format('woff2'),
        url('../../public/fonts/ACaslonPro/ACaslonPro-BoldItalic.otf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'ATTAleckSans';
  font-display: swap;
  src:  url('../../public/fonts/ATTAleckSans/ATTAleckSans-Regular--subset.woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ATTAleckSans';
  font-display: swap;
  src:  url('../../public/fonts/ATTAleckSans/ATTAleckSans-Medium--subset.woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'ATTAleckSans';
  font-display: swap;
  src:  url('../../public/fonts/ATTAleckSans/ATTAleckSans-Bold--subset.woff2');
  font-weight: bold;
  font-style: normal;
}
